<template>
    <div class="class">
        <el-row>
            <el-col :span="22">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="年度：" prop="actionYear" :label-width="formLabelWidth">
                            <el-date-picker v-model="frontActionForm.actionYear" disabled type="year" placeholder="请选择" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="牵头单位：" :label-width="formLabelWidth">
                            <el-cascader
                                @change="handleChange"
                                ref="cascader"
                                filterable
                                disabled
                                clearable
                                v-model="form.actionDeptIdList"
                                :props="defaultProps"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="22">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="重大任务名称：" prop="actionName" :label-width="formLabelWidth">
                            <el-input placeholder="请输入" disabled v-model="frontActionForm.actionName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="指标名称：" prop="indexName" :label-width="formLabelWidth">
                            <el-input placeholder="请输入" disabled v-model="frontIndexForm.indexName"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="22">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="任务类型：" prop="indexTypeText" :label-width="formLabelWidth">
                            <el-input placeholder="请输入" disabled v-model="frontIndexForm.indexTypeText"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="title == '查看'">
                        <el-form-item label="目标值：" prop="orderNum" :label-width="formLabelWidth">
                            <el-input placeholder="请输入" disabled v-model="frontIndexForm.orderNum"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-else>
                        <el-form-item label="截止日期：" prop="deadLine" :label-width="formLabelWidth">
                            <el-input placeholder="请输入" disabled v-model="form.deadLine"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="22">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="是否有数源应用：" :label-width="formLabelWidth" prop="whetherSource">
                            <el-radio-group disabled v-model="frontIndexForm.whetherSource" class="ml-4">
                                <el-radio label="0" size="large">是</el-radio>
                                <el-radio label="1" size="large">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="frontIndexForm.whetherSource == 0" label="数源应用名称：" prop="sourceName" :label-width="formLabelWidth">
                            <el-input placeholder="请输入" disabled v-model="frontIndexForm.sourceName"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="22">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="责任单位：" prop="orgName" :label-width="formLabelWidth">
                            <el-input placeholder="请输入" disabled v-model="form.orgName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="frontIndexForm.indexType == 0">
                        <el-form-item label="责任单位目标值：" prop="orgNum" :label-width="formLabelWidth" class="tagertNum">
                            <el-select v-model="frontIndexForm.indexNumSymbol" disabled class="select-input" placeholder="请选择">
                                <el-option :key="0" label=">" :value="0" />
                                <el-option :key="1" label="≥" :value="1" />
                                <el-option :key="2" label="<" :value="2" />
                                <el-option :key="3" label="≤" :value="3" />
                                <el-option :key="4" label="=" :value="4" />
                            </el-select>
                            <el-input placeholder="请输入" class="tagertInput" disabled v-model="form.orgNum"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-else></el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, defineEmits, defineProps, getCurrentInstance } from 'vue';
let dialogFormVisible = ref(true);
let formLabelWidth = '150px';
const { proxy } = getCurrentInstance();
const cascader = ref(null);
let cascader2 = ref(null);
let cascader3 = ref(null);
let emit = defineEmits();
let props = defineProps(['formData', 'title', 'id']);
const { formData, title, id } = props;
console.log(formData, title, id);
let form = ref({});
let frontIndexForm = ref({});
let frontActionForm = ref({});

const defaultProps = {
    lazy: true,
    multiple: true,
    checkStrictly: true,
    async lazyLoad(node, resolve) {
        // console.log(node,);
        const { level } = node;
        let data = level > 0 ? node.value : 'main';
        level + 1;
        let res = await axios({
            url: '/front/sysOrganization/getByParentExternalId',
            method: 'get',
            params: {
                parentExternalId: data,
            },
        }); //.then(  => {
        if (res) {
            let nodes = res.map(item => {
                return {
                    label: item.organizationName,
                    value: item.externalId,
                    leaf: false,
                    // deptId: item.deptId,
                };
            });
            if (res.length == 0) {
                // console.log(node);
                node.data.leaf = true;
            }
            resolve(nodes);
        }
        // });
    },
};
const getTaskById = () => {
    //根据id获取任务详情
    proxy.$api.frontIndexUnit.getById(id).then(res => {
        if (res.code == 200) {
            form.value = res.data;
            console.log('form', form.value);
            frontActionForm.value = res.data.frontAction;
            frontIndexForm.value = res.data.frontIndex;
            frontIndexForm.value.indexTypeText = res.data.frontIndex.indexType == 0 ? '定量任务' : '定性任务';
            frontIndexForm.value.indexNumSymbol ? (frontIndexForm.value.indexNumSymbol = Number(frontIndexForm.value.indexNumSymbol)) : '';
            frontIndexForm.value.unitList &&
                frontIndexForm.value.unitList.forEach(e => {
                    e.unitNumSymbol ? (e.unitNumSymbol = Number(e.unitNumSymbol)) : '';
                });

        }
    });
};
getTaskById();
</script>

<style scoped lang="stylus">
.tagertNum
    /deep/ .el-form-item__content
        flex-wrap: nowrap
        justify-content: space-between
</style>
