<template>
    <div class="task">
        <el-affix :offset="55">
            <header>
                <span class="head-but" @click="cancle"><el-button plain :icon="ArrowLeft">返回</el-button></span>
                <span class="head-t">{{ title }}</span>
            </header>
        </el-affix>
        <div class="form">
            <el-row>
                <el-col :span="22" class="audit-title" v-if="allDisabled">
                    <span class="text">基本信息</span>
                </el-col>
            </el-row>
            <TaskInfo :id="route.query.id" :formData="formData" :title="route.query.title || ''" />
            <el-form :model="form" :rules="rules" ref="ruleForm" :disabled="allDisabled">
                <el-row v-if="route.query.type == 1">
                    <el-col :span="22">
                        <el-row>
                            <el-col :span="12" v-if="frontIndexData.indexType == 1">
                                <el-form-item
                                    label="是否已完成："
                                    prop="doneFlag"
                                    required="true"
                                    :label-width="formLabelWidth"
                                >
                                    <el-radio-group v-model="form.doneFlag">
                                        <el-radio :label="1" size="large">是</el-radio>
                                        <el-radio :label="0" size="large">否</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" v-else>
                                <el-form-item label="完成值：" prop="completeNum" :label-width="formLabelWidth">
                                    <el-input placeholder="请输入" type="number" v-model="form.completeNum"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="填报日期：" prop="writeTime" :label-width="formLabelWidth">
                                    <el-date-picker
                                        v-model="form.writeTime"
                                        type="date"
                                        disabled
                                        placeholder="Pick a day"
                                        :default-value="formatDateTime(new Date())"
                                        :size="size"
                                    />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-form-item
                    label="密级："
                    v-if="envType === 'intranet' && form.actionSecret === '0'"
                    prop="classification"
                    :label-width="formLabelWidth"
                >
                    <el-select v-model="form.classification" class="select-input" placeholder="请选择">
                        <el-option
                            v-for="(item, index) in classificationMap"
                            :key="index"
                            :label="item"
                            :value="item"
                        />
                    </el-select>
                </el-form-item>
                <el-row v-if="!allDisabled">
                    <el-col :span="22">
                        <el-form-item label="附件：" :label-width="formLabelWidth">
                            <AddFile @putUrl="putUrl" :fileList="form.filePath || []" action="/api/oss/upload" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row v-if="allDisabled">
                <el-col :span="22" class="audit-title">
                    <span class="text">填报记录</span>
                </el-col>
                <el-col :span="22" class="table">
                    <AuditRaecords v-if="allDisabled" :type="3" :id="route.query.id" />
                </el-col>
            </el-row>
        </div>
        <el-row :span="24" class="footer" v-if="!allDisabled">
            <el-button @click="cancle">返 回</el-button>
            <el-button type="primary" @click="submit(ruleForm)">提交</el-button>
        </el-row>
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, defineEmits, defineProps, getCurrentInstance } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import AddFile from '@/components/addFile.vue';
import AuditRaecords from '@/components/auditRaecords';
import TaskInfo from '@/views/taskExecution/components/taskInfo';
import { formatDateTime } from '@/utils/common';
let store = useStore();
let userRole = ref(false);
const { proxy } = getCurrentInstance();
const envType = computed(() => {
    return process.env.VUE_APP_API_ENV_IRONMENT;
});
const classificationMap = {
    1: '秘密',
    2: '机密',
};
let rules = {
    completeNum: { required: true, message: '完成值不能为空，请填写完成值！', trigger: 'blur' },
    isComplete: { required: true, message: '请选择是否完成', trigger: 'blur' },
    filePath: { required: true, message: '请上传附件', trigger: 'blur' },
    classification: [{ required: true, message: '请选择指标密级', trigger: 'blur' }],
};
let roles = store.state.user.roles;
function isDisables(role) {
    let res = true;
    if (title.value == '编辑指标') {
        role.forEach((e) => {
            if (roles.indexOf(e) > -1) {
                res = false;
            }
            return res;
        });
    } else {
        res = false;
    }
    return res;
}
const router = useRouter();
let formLabelWidth = '150px';

const options = [
    {
        value: '1',
        label: '每月',
    },
    {
        value: '2',
        label: '每季度',
    },
    {
        value: '3',
        label: '每半年',
    },
    {
        value: '4',
        label: '每年',
    },
];

let formData = ref({});
let frontIndexData = ref({});
let ruleForm = ref(null);
let showTable = ref(false); //表格展示
let allDisabled = ref(false); //查看所有input禁用和按钮不展示
let emit = defineEmits();
const route = useRoute();
let title = ref('');
let date = new Date();
const tab = route.query.title == '填报' ? 1 : 2;
const form = ref({
    writeTime: formatDateTime(new Date()),
    filePath: [],
});
console.log(form.value);
// 表单数据初始化
function init() {
    console.log(route);
    title.value = route.query.title;
    form.value.unitId = route.query.id;
    if (route.query.title == '查看') {
        allDisabled.value = true;
    }
    getTaskById();
    // type为判断条件，1审核和指标查看，2是已审核重大任务查看，3是已审核指标查看
    // if (route.query.type) {
    //     showTable.value = true;
    // }
}

const submit = async (ruleForm) => {
    await ruleForm.validate((valid, fields) => {
        if (!valid) {
            return;
        }
        let method = route.query.title == '修改' ? 'updateFillIndexUnitDoneNumber' : 'fillIndexUnitDoneNumber';
        let newForm = Object.assign({}, form.value);
        // 区分内网和互联网环境的上传,内网不能用oss
        if (process.env.VUE_APP_API_ENV_IRONMENT === 'intranet') {
            newForm.fileUrl = form.value.filePath.map((e) => e.url).join(',');
            newForm.fileName = form.value.filePath.map((e) => e.name).join(',');
        } else {
            newForm.attachmentKey = form.value.filePath.map((e) => e.attachmentKey).join(',');
            newForm.attachmentName = form.value.filePath.map((e) => e.attachmentName).join(',');
            newForm.attachmentSize = form.value.filePath.map((e) => e.attachmentSize).join(',');
        }
        proxy.$api.frontIndexUnit[method](newForm).then((res) => {
            if (res.code == 200) {
                if (route.query.type == 1 && res.data.doneFlag == 1) {
                    ElMessage.success('填报完成，该任务已完成目标值，任务状态更新为已完成');
                } else {
                    ElMessage.success('填报完成');
                }
                router.push({
                    path: '/taskExecution',
                    query: {
                        tab: tab,
                    },
                });
            } else {
                ElMessage.error(res.msg);
            }
        });
    });
};

const getTaskById = () => {
    //根据id获取任务详情
    proxy.$api.frontIndexUnit.getById(route.query.id).then((res) => {
        if (res.code == 200) {
            formData.value = res.data;
            frontIndexData.value = res.data.frontIndex;
            form.value.writeTime = res.data.writeTime;
            form.value.completeNum = res.data.completeNum;
            form.value.doneFlag = res.data.doneFlag;
            form.value.filePath = res.data.filePath;
            form.value.sourceName = res.data.sourceName;
            form.value.orgNum = res.data.orgNum;
            form.value.unitNumSymbol = res.data.unitNumSymbol;
            form.value.indexNum = res.data.indexNum;
            console.log(formData.value, frontIndexData.value);
        }
    });
};

function putUrl(url) {
    console.log(url, form.value.filePath);
    form.value.filePath = url;
}
let cancle = () => {
    router.push({
        path: '/taskExecution',
        query: {
            tab: tab,
        },
    });
};
onMounted(() => {
    console.log('userinfo', store.state.user);
    init();
    let formBox = document.getElementsByClassName('form');
    if (route.query.title.indexOf('查看') > -1) {
        formBox[0].style.height = 'calc(100% - ' + 100 + 'px)';
    }
});
</script>

<style scoped lang="stylus">
.task
    height: calc(100% - 32px)
    header
        // width: 1200px;
        height: 56px;
        line-height: 56px
        text-align: center
        background: #FFFFFF;
        box-shadow: 0 0 10px -6px rgba(0,0,0,0.30);
        border-radius: 4px;
        padding-left 24px
        .head-but
            float left
        .head-t
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 18px;
            color: #333333;
    .form
        padding 24px 24px 25px
        background: #FFFFFF;
        .footer
            height: 64px;
            justify-content: center
            border-top 1px solid #EEEEEE
            align-items: center !important
            /deep/ .el-form-item
                margin-bottom 0
        .milestone
            width 100%
            height 100%
    .title
        text-align: left
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 16px

    .add-unit
        margin-bottom 24px
        color: #1492FF;
        text-align center
    .unit-input
        position relative
    .icon
        width: 16px;
        height: 16px;
        position absolute
        top 8px
        right -25px
        background: url('~@/assets/img/delete.png') no-repeat
    .tagertNum
        /deep/ .el-form-item__content
            flex-wrap: nowrap
            justify-content: space-between
/deep/ .el-cascader
    width 39.2vw
/deep/ .el-select
    width 35vw
/deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf
    border-bottom: 0px solid #fff
/deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf
    border-bottom: 0px solid #fff
/deep/ .el-row
    justify-content center
</style>
